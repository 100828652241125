import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  TextBox,
  Image,
  BannerLinks,
  Breadcrumbs,
  BlogSlider,
  Appointment
} from '../../components'

import BannerImage from '../../assets/projektuebersicht.jpg'
import boxes from '../../components/BannerLinks/DefaultBox'
import cards from '../../components/BlogSlider/DefaultCards'

import { generateBreadcrumbsLinks } from '../../utils/index'

import matomo_demo from '../../assets/matomo_demo.png'
import Georg from '../../assets/georg_neu.png'

const SplitScreen = () => {
  const links = generateBreadcrumbsLinks(
    '/blog/matomo_statt_google',
    'matomo statt Google'
  )
  return (
    <Layout>
      <Helmet
        title="matomo statt Google Analytics"
        meta={[
          {
            name: 'description',
            content:
              'Ab jetzt heißt es Matomo statt Google Analytics! Google Analytics ist nicht mehr DSGVO konform'
          },
          {
            name: 'keywords',
            content:
              'holzweg, matomo, dsgvo, Datenschutz, google analytics, websites'
          },
          {
            name: 'image',
            content:
              'https://www.holzweg.com/static/matomo_m-a1b8b0cba88664b4e21ac50941966afe.png'
          },
          {
            property: 'og:description',
            content:
              'Ab jetzt heißt es Matomo statt Google Analytics! Google Analytics ist nicht mehr DSGVO konform'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/matomo_m-a1b8b0cba88664b4e21ac50941966afe.png'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            matomo
            <br />
            statt Google Analytics
          </span>
        }
      />
      <Breadcrumbs backLinkAlias=" " links={links} />
      <TextBox
        title={<span>Warum die Umstellung??</span>}
        text={
          <span>
            <p>
              <b>Google Analytics verstößt gegen die Datenschutzverordnung. </b>
              Die österreichische, sowie die französische Datenschutzbehörde
              (DSB) kommen aufgrund einer Musterbeschwerde, die vom{' '}
              <a href="https://noyb.eu/de" target="_blank">
                Verein Noyb rund um Max Schrems
              </a>{' '}
              eingebracht wurde, zu dem Schluss, dass die Einbindung von Google
              Analytics auf Webseiten gegen die Datenschutzgrundverordnung
              (DSGVO) verstößt. Laut Bescheid wurde “einer Verletzung der
              allgemeinen Grundsätze der Datenübermittlung gemäß Art. 44 DSGVO”
              stattgegeben. Diese regelt die Weitergabe von personenbezogener
              Daten an Drittländer bzw. an internationale Organisationen. Im
              konkreten Fall seien zumindest "eine einzigartige
              Nutzer-ID-Nummer, IP-Adresse und Browserparameter" an Google
              übermittelt worden. Das heißt soviel wie, dass ein eindeutiges
              Userprofil inklusive verwendeter Geräte- bzw. Router-Adresse (IP)
              und des genutzten Browsers erstellt werden kann.
            </p>
            <h3>
              Welche Strafen könnten auf ein Unternehmen zukommen, die Google
              Analytics verwenden??
            </h3>
            <p>
              {' '}
              <a
                href="https://www.wko.at/service/wirtschaftsrecht-gewerberecht/EU-Datenschutz-Grundverordnung:-Rechtsdurchsetzung-und-St.html"
                target="_blank"
              >
                Strafen nach der DSGVO:
              </a>{' '}
              Im Falle eines Unternehmens, können Geldbußen von bis zu 4% des
              gesamten weltweit erzielten Jahresumsatzes des vorangegangenen
              Geschäftsjahres verhängt werden. Das kann ganz schön teuer werden…
              <b>
                Ein Glück, dass wir die passende Lösung für Sie gefunden haben!!
              </b>
              Wir steigen jetzt mit vielen Kund:innen auf die Webanalysenplattform
              von{' '}
              <a href="https://matomo.org/" target="_blank">
                matomo
              </a>{' '}
              um.
            </p>

            <h3>Warum matomo??</h3>
            <p>
              <b>
                Matomo ist eine der leistungsstärksten Webanalyseplattformen,
                die Ihnen 100 % der Daten zur Verfügung stellt.{' '}
              </b>
            </p>
            <b>Wie stellt matomo das an??</b>
            <ul>
              <li>
                Nach dem Übergang haben Sie 100%ige Kontrolle über Ihre Daten,
                da Matomo auf eigenen Servern gehostet wird. Sie entscheiden
                also, was damit passiert und wo Sie Ihre Daten speichern wollen.
                Auch Matomo selbst hat dann keine Möglichkeit, auf Ihre Daten
                zuzugreifen.
              </li>
            </ul>
            <b>Mit matomo das richtige Signal senden!</b>
            <ul>
              <li>
                Laut einer Studie von PricewaterhouseCoopers (pwc) würden 71 %
                der Befragten nicht mehr mit einem Unternehmen zusammenarbeiten,
                das ihre sensiblen Daten unerlaubt weitergibt. Um treue Kund:innen
                zu gewinnen, die Ihre Marke lieben, ist es wichtig, das richtige
                Signal zu senden, dass Sie Ihre Kund:innen respektieren und ihre
                Privatsphäre schützen.
              </li>
            </ul>
            <b>
              Nicht umsonst entscheiden sich auch viele Regierungsbehörden für
              matomo…
            </b>
            <ul>
              <li>
                Die führende Rolle von Matomo im Bereich des Datenschutzes
                spiegelt sich in den vielen Regierungsbehörden wieder, die
                Matomo bereits verwenden. Beispiele hier sind z.B. Das Presse-
                und Informationsamt der Bundesregierung in Deutschland, die
                neuseeländische Regierung oder auch die Europäische Kommission
                für Europa Analytics.
              </li>
            </ul>

            <h3>Zum Dashboard:</h3>
          </span>
        }
      />
      <Image image={matomo_demo} title="" />
      <TextBox
        title=""
        text={
          <span>
            <h3>Vertrauen ist gut, Kontrolle ist besser</h3>
            <p>
              Mit matomo entscheiden <b>SIE</b> wo die Daten ihrer Kund:innen
              gespeichert werden.
            </p>
            <p>Gehen auch Sie mit uns den nächsten Schritt.</p>
          </span>
        }
      />

      <Appointment image={Georg} />
      <BlogSlider
        cards={cards}
        singleRow={true}
        title="Weitere Blogbeiträge:"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default SplitScreen
